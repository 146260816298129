import {GetState} from '../types'
import {callAPI, createActions} from '../../../commons/actions/data-action-helper'

export const GET_CONNECTED_GROUP = createActions('GET_CONNECTED_GROUP')
export const GET_GROUP_ACTIVITY = createActions('GET_GROUP_ACTIVITY')

export const getConnectedGroup = () => async (dispatch: Function, getState: GetState) => {
  const response = await dispatch(callAPI(GET_CONNECTED_GROUP, getState().event.id))
  if (response) {
    await dispatch(getGroupActivity({groupId: response.group.id}))
  }
}

export const getGroupActivity =
  ({groupId}: {groupId: string}) =>
  async (dispatch: Function) => {
    await dispatch(callAPI(GET_GROUP_ACTIVITY, groupId))
  }
